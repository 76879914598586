<template>
  <div class="row mx-0">
    <div class="col-12 p-0" >
      <!-- TABLE HEADING -->
      <div class="row m-0">
        <div class="col-12 px-0">
          <!--DESKTOP-->
          <div class="row mx-0 font13 green-text-dark d-none d-md-flex">
            <div class="col-md-3 bold text-md-center px-1">
              <div class="lightgrey_bg w-100 h-100 p-3 rounded-left">
                DONOR
              </div>
            </div>
            <div class="col-md-2 bold text-md-center px-0">
              <div class="lightgrey_bg w-100 h-100 p-3">
                PLEDGE REF
              </div>
            </div>
            <div class="col-md-2 bold text-md-center px-1">
              <div class="lightgrey_bg w-100 h-100 p-3">
                DATE
              </div>
            </div>
            <div class="col-md-1 bold text-md-center px-0">
              <div class="lightgrey_bg w-100 h-100 p-3">
                AMOUNT
              </div>
            </div>
            <div class="col-md-2 bold text-md-center px-1">
              <div class="lightgrey_bg w-100 h-100 p-3">
                PLEDGE TYPE
              </div>
            </div>
            <div class="col-md-2 bold text-md-center px-0">
              <div class="lightgrey_bg w-100 h-100 p-3 rounded-right">
                INFO
              </div>
            </div>
          </div>
          <!--END DESKTOP-->
          <!--MOBILE-->
          <div class="row font13 green-text-dark d-md-none">
            <div class="col-3 bold text-center px-0">
              Pledge Ref
            </div>
            <div class="col-3 bold text-center px-0">
              Amount
            </div>
            <div class="col-3 bold text-center px-0">
              Date
            </div>
            <div class="col-3 bold text-center px-0">
              Info
            </div>
          </div>
          <!--END MOBILE-->
        </div>
      </div>
      <!-- END TABLE HEADING -->
      <div class="row m-0" >
        <div class="col-12 px-0" v-if="pledges && pledges.length > 0">
          <PledgesItem v-for="(donation, key) in pledges.slice(start, finish)" :key="key" :item="donation" :thekey="key" @showDetails="showDetails(key)" />
        </div>
        <div class="col-12 pt-3 text-center" v-else>
          You don't have any Pledges to show.
        </div>
      </div>
      <transition name="slideup">
      <PledgesPopup v-if="currentItem" :item="currentItem" :theKey="currentKey" :totalItems="pledges.length" @close="closeDetails" @goForward="goForward(currentKey)" @goBack="goBack(currentKey)" />
      </transition>
      <!-- PAGINATION -->
      <div class="row mx-0 mt-5" v-if="totalPages > 1">
        <div class="col-12 text-center">
          <div class="d-inline p-2 cursor" v-for="index in totalPages" :key="index" @click="goToPage(index)" :class="{bold: currentPage === index - 1}">
            {{ index }}
          </div>
        </div>
      </div>
      <!-- END PAGINATION -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    PledgesItem: defineAsyncComponent(() => import('./PledgesItem.vue')),
    PledgesPopup: defineAsyncComponent(() => import('./PledgesPopup.vue'))
  },
  name: 'PledgesTable',
  data () {
    return {
      projectType: null,
      startDate: null,
      endDate: null,
      priceRange: null,
      donationType: null,
      itemsPerPage: 10,
      currentPage: 0,
      currentItem: null,
      currentKey: null,
      isDeleteDebit: false,
      currentID: null
    }
  },
  computed: {
    ...mapGetters(['user', 'pledges']),
    totalPages () {
      return Math.ceil(this.pledges.length / this.itemsPerPage)
    },
    start () {
      return this.currentPage * this.itemsPerPage
    },
    finish () {
      return this.start + this.itemsPerPage
    }
  },
  methods: {
    goToPage (val) {
      this.currentPage = val - 1
    },
    showDetails (val) {
      // this.currentItem = this.pledges[val]
      // this.currentKey = val

      this.currentKey = this.start + val
      this.currentItem = this.pledges[this.currentKey]
    },
    goForward (val) {
      this.currentItem = this.pledges[val + 1]
      this.currentKey = val + 1
    },
    goBack (val) {
      this.currentItem = this.pledges[val - 1]
      this.currentKey = val - 1
    },
    closeDetails () {
      this.currentItem = null
      this.currentKey = null
    }
  }
}
</script>

<style scoped>
.rounded-left {
  border-bottom-left-radius: 100px;
  border-top-left-radius: 100px;
}
.rounded-right {
  border-bottom-right-radius: 100px;
  border-top-right-radius: 100px;
}
@media (min-width: 992px) {
  .rounded-left {
    border-bottom-left-radius: 100px;
    border-top-left-radius: 100px;
  }
  .rounded-right {
    border-bottom-right-radius: 100px;
    border-top-right-radius: 100px;
  }
}
.slideup-enter-active,
.slideup-leave-active {
  transition: all 0.5s ease;
}

.slideup-enter-from,
.slideup-leave-to {
  opacity: 0;
  transform: translateY(100%);
}
</style>
